<template>
  <v-row class="company-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-12 pt-0 px-0">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>
                
          <table v-else class="width-100">
            <template v-if="true">
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Security Code</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ company.security_code }}
                </td>
              </tr>
              <!-- <tr>
                <td width="200" class="py-1 font-size-18 text-capitalize">
                  Customer Type
                </td>
                <td class="py-1">
                  <span>
                    <v-chip
                      small
                      color="blue white--text"
                      label
                      v-if="company.company_type == 'company'"
                    >
                      <span class="font-size-16"> Company </span>
                    </v-chip>
                    <v-chip
                      small
                      color="red white--text"
                      label
                      v-if="company.company_type == 'personal'"
                    >
                      <span class="font-size-16"> Individual </span>
                    </v-chip>
                  </span>
                </td>
              </tr> -->
              <tr>
                <td width="200" class="py-1 font-size-18 text-capitalize">
                  Full Name 
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.company_name">
                         {{company.company_name}}
                  </template>
                  <template v-else
                    ><em class="text-muted">No Full Name</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18">UEN</td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="company.uen">{{ company.uen }}</template>
                  <template v-else><em class="text-muted">No uen</em></template>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Website</td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="company.website">{{
                    company.website
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Website</em></template
                  >
                </td>
              </tr>

              <tr>
                <td class="py-1 font-size-18 text-capitalize">Company Ref #</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.sap_customer_no">{{
                    company.sap_customer_no
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Company Ref #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Ref No.</td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="company.cust_vend_ref_no">{{
                    company.cust_vend_ref_no
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Ref No.</em></template
                  >
                </td>
              </tr>
              <!-- <tr>
                <td class="py-1 font-size-18 text-capitalize">Tagss</td>
                
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="company?.tags?.length >0">
                  
                    <v-chip
                      v-for="(row, index) in company.tags"
                      :key="index"
                      :color="row.color"
                      class="mb-1"
                      >{{ row.text }}</v-chip
                    >
                  </template>
                  <template v-else
                    ><em class="text-muted">No tags</em></template
                  >
                </td>
              </tr> -->
            </template>
            <template v-else>
              <tr>
                <td class="py-1 font-size-18 text-capitalize" width="200">
                  Name
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  {{ company.default_person?.full_name }}
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Company Ref #</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.sap_customer_no">{{
                    company.sap_customer_no
                  }}</template>
                  <template v-else>
                    <em class="text-muted">No company ref #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Ref No.</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.cust_vend_ref_no">{{
                    company.cust_vend_ref_no
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No ref no.</em></template
                  >
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  name: "contact-details",
  title: "Company",
  mixins: [CommonMixin, FileManagerMixin],
  data() {
    return {
      imageLoading: false,
      pageLoading:true,
    };
  },
  props: {
    company: {
      type: Object,
      /* required: true, */
      default: function () {
        return {};
      },
    },
    // pageLoading: {
    //   type: Boolean,
    //   default: true,
    // },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      let customerId = _this.company && _this.company.id ? _this.company.id : 0;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param, customerId, "company")
        .then((response) => {
          _this.company.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {},
  computed: {
    customerActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.company) === false) {
        return _this.company.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.company &&
        _this.lodash.isEmpty(_this.company.persons) === false
      ) {
        for (let i = 0; i < _this.company.persons?.length; i++) {
          if (_this.company.persons[i].default) {
            name =
              _this.lodash.startCase(_this.company.persons[i].title) +
              ". " +
              _this.company.persons[i].first_name +
              " " +
              _this.company.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.company &&
        _this.lodash.isEmpty(_this.company.details) === false
      ) {
        for (let i = 0; i < _this.company.details?.length; i++) {
          if (_this.company.details[i].type === 1) {
            email = _this.company.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.company &&
        _this.lodash.isEmpty(_this.company.details) === false
      ) {
        for (let i = 0; i < _this.company.details?.length; i++) {
          if (_this.company.details[i].type === 2) {
            phone = _this.company.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.company.profile_logo) {
        return null;
      }
      return this.company.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.company.barcode) {
        return "";
      }
      return this.company.barcode;
    },
  },
};
</script>
