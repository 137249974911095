<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 90vh; position: relative"
      >
        <v-layout class="d-block">
          <v-row>
            <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
              <v-container fluid>
                <v-row>
                  <v-col class="pt-0" md="12" sm="12">
                    <v-row>
                      <v-col
                        v-if="!isDialog"
                        class="pt-0 d-flex"
                        md="12"
                        sm="12"
                      >
                        <v-switch
                          v-model="billingAddress.is_same"
                          class="mt-0"
                          inset
                          :true-value="1"
                          :false-value="0"
                          v-on:change="copyAddress()"
                          color="cyan"
                          label="Billing address is same as Address"
                        ></v-switch>
                      </v-col>
                      <v-col md="6" sm="6">
                        <v-switch
                          v-if="false"
                          v-model="trackGPS"
                          class="mt-0"
                          inset
                          label="Get your current location"
                          v-on:change="getUserCurrentAddress"
                          color="cyan"
                        ></v-switch>
                      </v-col>
                      <v-col md="6" sm="6" v-if="false">
                        <v-hover v-slot="{ hover }">
                          <div class="custom-google-autocomplete">
                            <v-text-field
                              id="address-map"
                              v-model.trim="searchAddress"
                              append-icon="search"
                              dense
                              filled
                              placeholder="Search Address"
                              solo
                              flat
                              color="cyan"
                              :loading="autocompleteLoading"
                              v-on:keyup="getGoogleAddress"
                              v-on:blur="isFocused = false"
                              v-on:focus="isFocused = true"
                            >
                            </v-text-field>
                            <v-list
                              v-if="
                                (hover || isFocused) && autocompleteList.length
                              "
                              two-line
                              elevation="4"
                              class="custom-google-autocomplete-list"
                            >
                              <v-list-item
                                v-for="(address, index) in autocompleteList"
                                :key="index"
                                link
                                v-on:click="selectGoogleAddress(index)"
                              >
                                <v-list-item-icon class="m-0 mr-3 my-auto">
                                  <v-icon color="cyan"> mdi-map-marker </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content
                                  class="py-1"
                                  v-if="
                                    lodash.isEmpty(
                                      address.structured_formatting
                                    ) === false
                                  "
                                >
                                  <v-list-item-title class="font-size-14">{{
                                    address.structured_formatting.main_text
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle class="font-size-12">{{
                                    address.structured_formatting.secondary_text
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content class="py-1" v-else>
                                  <v-list-item-title class="font-size-14">{{
                                    address.description
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="6" sm="12" class="mb-4">
                    <label class="font-size-16 font-weight-500 required"
                      >Country
                    </label>
                    <v-autocomplete
                      :items="countryList"
                      v-model.trim="billingAddress.country"
                      hide-details
                      flat
                      :rules="[
                        validateRules.required(
                          billingAddress.country,
                          'Country'
                        ),
                      ]"
                      solo
                      dense
                      item-value="value"
                      item-text="text"
                      placeholder="Country"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="countryCheck($event)"
                      class="pt-0 mt-0"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6" sm="12" class="mb-4">
                    <label class="font-size-16 font-weight-700"
                      >Search Address
                    </label>
                    <SearchAddress
                      variant="outlined"
                      density="compact"
                      hide-details
                      :country="billingAddress.country"
                      :country-code="country_code"
                      @update:address="(data) => setAddress(data)"
                      :placeholder="
                        billingAddress.country == 'singapore'
                          ? 'Search Address'
                          : 'Search Address'
                      "
                    />
                  </v-col>
                  <v-col md="12" sm="12">
                    <v-row>
                      <v-col md="6">
                        <label class="font-size-16 font-weight-500"
                          >Contact Person
                        </label>
                        <v-select
                          id="contact-persons"
                          v-model.trim="billingAddress.contact_person"
                          :items="cc_persons"
                          :rules="[
                            validateRules.required(
                              billingAddress.contact_person,
                              'Contact Person'
                            ),
                          ]"
                          dense
                          filled
                          item-text="display_name"
                          item-value="uuid"
                          item-color="cyan"
                          placeholder="Contact Persons"
                          solo
                          flat
                          color="cyan"
                        ></v-select>
                      </v-col>
                      <v-col md="6" sm="12">
                        <label class="font-size-16 font-weight-500"
                          >Site Location Name</label
                        >
                        <v-text-field
                          v-model.trim="billingAddress.property_name"
                          dense
                          filled
                          placeholder="Site Location Name."
                          solo
                          flat
                          color="cyan"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <template v-if="billingAddress.country == 'singapore'">
                    <v-col md="6" sm="12">
                      <label class="font-size-16 font-weight-500 required">
                        <template v-if="billingAddress.country == 'singapore'">
                          Postal Code
                        </template>
                        <template v-else> Find Address </template>
                      </label>
                      <v-text-field
                        v-model.trim="billingAddress.zip_code"
                        :rules="[
                          validateRules.required(
                            billingAddress.zip_code,
                            'Postal Code'
                          ),
                          validateRules.maxLength(
                            billingAddress.zip_code,
                            'Postal Code',
                            6
                          ),
                          validateRules.minLength(
                            billingAddress.zip_code,
                            'Postal Code',
                            2
                          ),
                        ]"
                        dense
                        filled
                        @keypress="restrictNonNumericInput($event)"
                        placeholder="Postal Code"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col
                      md="6"
                      sm="6"
                      v-if="billingAddress.country != 'singapore'"
                    >
                      <label class="font-size-16 font-weight-500 required"
                        >Full Address</label
                      >
                      <v-textarea
                        v-model.trim="billingAddress.street_1"
                        :rules="[
                          validateRules.required(
                            billingAddress.street_1,
                            'Full Address'
                          ),
                          validateRules.maxLength(
                            billingAddress.street_1,
                            'Full Address',
                            255
                          ),
                        ]"
                        auto-grow
                        dense
                        filled
                        placeholder="Full Address"
                        solo
                        flat
                        row-height="15"
                        color="cyan"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      md="6"
                      sm="12"
                      v-if="billingAddress.country == 'singapore'"
                    >
                      <label class="font-size-16 font-weight-500 required"
                        >Postal Code</label
                      >
                      <v-text-field
                        v-model.trim="billingAddress.zip_code"
                        :rules="[
                          validateRules.required(
                            billingAddress.zip_code,
                            'Postal Code'
                          ),
                          validateRules.maxLength(
                            billingAddress.zip_code,
                            'Postal Code',
                            6
                          ),
                          validateRules.minLength(
                            billingAddress.zip_code,
                            'Postal Code',
                            2
                          ),
                        ]"
                        dense
                        filled
                        @keypress="restrictNonNumericInput($event)"
                        placeholder="Postal Code"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>

                  <v-col
                    md="6"
                    sm="12"
                    v-if="billingAddress.country == 'singapore'"
                  >
                    <label class="font-size-16 font-weight-500">Unit No.</label>
                    <v-text-field
                      v-model.trim="billingAddress.unit_no"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.unit_no,
                          'Unit No.',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Unit No."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="6"
                    sm="12"
                    v-if="billingAddress.country == 'singapore'"
                  >
                    <label class="font-size-16 font-weight-500 required"
                      >Address Line 1</label
                    >
                    <v-text-field
                      v-model.trim="billingAddress.street_1"
                      :rules="[
                        validateRules.required(
                          billingAddress.street_1,
                          'Address Line 1'
                        ),
                        validateRules.maxLength(
                          billingAddress.street_1,
                          'Address Line 1',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Address Line 1"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="6"
                    sm="12"
                    v-if="billingAddress.country == 'singapore'"
                  >
                    <label class="font-size-16 font-weight-500"
                      >Address Line 2</label
                    >
                    <v-text-field
                      v-model.trim="billingAddress.street_2"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.street_2,
                          'Address Line 2',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Address Line 2"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <v-text-field
                      v-model.trim="billingAddress.latitude"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.latitude,
                          'Latitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Latitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <v-text-field
                      v-model.trim="billingAddress.longitude"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.longitude,
                          'Longitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Longitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="6">
                    <label class="font-size-16 font-weight-500"
                      >Remark For Address</label
                    >
                    <v-textarea
                      v-model.trim="billingAddress.remarks"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.remarks,
                          'Remarks',
                          512
                        ),
                      ]"
                      auto-grow
                      dense
                      filled
                      placeholder="Remarks"
                      solo
                      flat
                      row-height="15"
                      v-on:keypress="(e) => manageLimitdescr(e)"
                      v-on:paste="(e) => onPaste(e)"
                      color="cyan"
                    ></v-textarea>
                    <div style="text-align: end">
                      {{
                        billingAddress.remarks
                          ? billingAddress.remarks.length
                          : 0
                      }}/200
                    </div>
                  </v-col>
                  <!--  <v-col md="12" sm="12">
                    <v-row class="py-0">
                      <v-col class="py-0" md="6">
                        <label class="font-size-16 font-weight-500"
                          >Remarks</label
                        >
                        <v-textarea
                          v-model.trim="billingAddress.remarks"
                          :rules="[
                            validateRules.maxLength(
                              billingAddress.remarks,
                              'Remarks',
                              200
                            ),
                          ]"
                          auto-grow
                          dense
                          filled
                          label="Remarks"
                          solo
                          flat
                          row-height="30"
                          v-on:keypress="(e) => manageLimitdescr(e)"
                          v-on:paste="(e) => onPaste(e)"
                          color="cyan"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-col md="6" class="text-right"
                      >{{
                        billingAddress.remarks
                          ? billingAddress.remarks.length
                          : 0
                      }}/200</v-col
                    >
                  </v-col> -->
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-layout>
      </perfect-scrollbar>
    </v-col>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { QUERY } from "@/core/services/store/request.module";
import SearchAddress from "@/view/components/Search-Address";

export default {
  name: "billing-address",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    property: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lead: {
      type: Object,
      default: function () {
        return {};
      },
    },
    defaultPosition: {
      type: Object,
      default: function () {
        return {};
      },
    },
    billingData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      country_code: "sg",
      autocompleteList: new Array(),
      searchAddress: null,
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      billingAddress: {
        id: null,
        uuid: null,
        type: 2,
        is_same: 0,
        contact_person: null,
        unit_no: null,
        property_name: null,
        street_1: null,
        street_2: null,
        zip_code: null,
        country: "singapore",
        latitude: null,
        longitude: null,
        remarks: null,
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    lead: {
      deep: true,
      immediate: true,
      handler() {
        this.getLead();
      },
    },
    cc_persons: {
      deep: true,
      handler() {
        this.billingAddress.contact_person = null;
      },
    },
    billingAddress: {
      deep: true,
      immediate: true,
      handler(param) {
        param.latitude = param.latitude ? param.latitude.toString() : null;
        param.longitude = param.longitude ? param.longitude.toString() : null;

        const filteredData = this.cc_persons.filter(
          (item) => item.id === param.contact_person
        );

        if (filteredData.length > 0) {
          param.contact_person = filteredData[0].uuid;
        }

        this.$emit("saveBillingAddress", param);
      },
    },

    defaultPosition: {
      deep: true,
      handler(param) {
        if (param.lat && param.lng && !param.custom) {
          this.getStreetAddressFrom(param.lat, param.lng);
        }
      },
    },
    billingData: {
      deep: true,
      handler(param) {
        const _this = this;
        if (_this.lodash.isEmpty(param) === false) {
          _this.billingAddress.id = param.id;
          _this.billingAddress.type = param.type;

          const filteredData = _this.cc_persons.filter(
            (item) => item.id === param.contact_person
          );
          if (filteredData.length > 0) {
            _this.billingAddress.contact_person = filteredData[0].uuid;
          }

          _this.billingAddress.is_same = param.is_same;
          _this.billingAddress.unit_no = param.unit_no;
          _this.billingAddress.unit_no = param.property_name;
          _this.billingAddress.street_1 = param.street_1;
          _this.billingAddress.street_2 = param.street_2;
          (_this.billingAddress.country = param.country
            ? param.country
            : "singapore"),
            (_this.billingAddress.zip_code = param.zip_code);
          _this.billingAddress.latitude = param.latitude;
          _this.billingAddress.longitude = param.longitude;
          _this.billingAddress.remarks = param.remarks;
          let code = "";
          code = this.lodash.find(this.countryList, function (row) {
            if (row.value == _this.billingAddress.country) {
              return row.country_code;
            } else {
              return false;
            }
          });

          this.country_code = code.country_code;
        }
      },
    },
  },
  methods: {
    /* manageLimit(e) {
      if (
        this.billingAddress.remarks &&
        this.billingAddress.remarks.length > 199
      ) {
        e.preventDefault();
      }
    }, */
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });
      this.country_code = code.country_code;
      this.billingAddress.zip_code = null;
      this.billingAddress.street_1 = null;
      this.billingAddress.street_2 = null;
      this.billingAddress.unit_no = null;
      this.billingAddress.longitude = null;
      this.billingAddress.latitude = null;
    },
    setAddress(e) {
      this.billingAddress.zip_code = this.lodash.cloneDeep(e.postal_code);
      this.billingAddress.street_1 = e.address1;
      this.billingAddress.street_2 = e.address2;
      this.billingAddress.unit_no = e.unit_number;
      this.billingAddress.longitude = e.longitude;
      this.billingAddress.latitude = e.latitude;
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.billingAddress.remarks;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.billingAddress.remarks = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.billingAddress.remarks &&
        this.billingAddress.remarks.length > 199
      ) {
        e.preventDefault();
      }
    },

    copyAddress() {
      const _this = this;
      this.$nextTick(() => {
        if (!_this.isDialog) {
          if (_this.billingAddress.is_same) {
            if (_this.lodash.isEmpty(_this.property) === false) {
              _this.billingAddress.contact_person =
                _this.property.contact_person;
              _this.billingAddress.latitude = _this.property.latitude;
              _this.billingAddress.longitude = _this.property.longitude;
              _this.billingAddress.property_name = _this.property.property_name;
              _this.billingAddress.street_1 = _this.property.street_1;
              _this.billingAddress.street_2 = _this.property.street_2;
              _this.billingAddress.unit_no = _this.property.unit_no;
              _this.billingAddress.country = _this.property.country;
              _this.billingAddress.zip_code = _this.property.zip_code;
              _this.billingAddress.remarks = _this.property.remarks;
            }
          } else {
            _this.clearAddress();
            _this.billingAddress.contact_person = null;
          }
        }
      });
    },
    getLead() {
      const _this = this;
      _this.billingAddress.zip_code = _this.lead.zip;
      _this.billingAddress.country = _this.lead.country;
      _this.billingAddress.unit_no = _this.lead.unit_no;
      _this.billingAddress.street_1 = _this.lead.address_1;
      _this.billingAddress.street_2 = _this.lead.address_2;
      _this.billingAddress.longitude = _this.lead.longitude;
      _this.billingAddress.latitude = _this.lead.latitude;
    },
    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.billingAddress.latitude = null;
      _this.billingAddress.longitude = null;
      _this.billingAddress.street_1 = null;
      _this.billingAddress.property_name = null;
      _this.billingAddress.street_2 = null;
      //_this.billingAddress.unit_no = null;
      _this.billingAddress.country = "singapore";
      _this.billingAddress.zip_code = null;
      //_this.billingAddress.property_name=null;
      _this.billingAddress.unit_no = null;
      _this.billingAddress.remarks = null;
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.billingAddress.latitude = result.latitude;
              _this.billingAddress.longitude = result.longitude;
              _this.billingAddress.street_1 = result.street_1;
              _this.billingAddress.street_2 = result.street_2;
              // _this.billingAddress.property_name = result.property_name;
              // _this.billingAddress.unit_no = result.unit_no;
              _this.billingAddress.zip_code = result.zip_code;
              _this.$emit("updateMapMarker", {
                lat: result.latitude,
                long: result.longitude,
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getStreetAddressFrom(lat, long) {
      const _this = this;
      try {
        if (lat && long) {
          _this.autocompleteLoading = true;
          _this.$store
            .dispatch(QUERY, {
              url: "google-api/lat-long",
              data: { lat, long },
            })
            .then((response) => {
              _this.$emit("updateMapMarker", { lat, long });
              _this.formatAddress(response.data);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.autocompleteLoading = false;
            });
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    getUserCurrentAddress() {
      const _this = this;
      if (_this.trackGPS === true) {
        _this.clearAddress();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.getStreetAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            _this.logError(error);
          }
        );
      }
    },
  },
  components: {
    SearchAddress,
  },
  computed: {
    ...mapGetters(["cc_persons"]),
  },
  mounted() {
    if (this.billingAddress.is_same != 0) {
      this.copyAddress();
    }
    /* if (this.billing > 0) {
      this.billingAddress.country = this.billingAddress.country;
    } */
    if (this.billing == 0) {
      this.billingAddress.country = "singapore";
    }
    /* this.$nextTick(() => {
      if (this.billing > 0) {
        let result = this.getBillingAddress(this.customer);
        if (this.lodash.isEmpty(result) === false) {
          this.billingAddress = result;
          this.billingAddress.country = result.country_name;
        }
      }
    }); */
  },
};
</script>
