<template>
  <v-container fluid>
    <v-form
      ref="passwordForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <input type="hidden" v-model.trim="formData.customer" />
        <v-col md="6" class="py-2" offset-md="3">
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            v-on:click:append="show2 = !show2"
            dense
            @keydown.space="(event) => event.preventDefault()"
            filled
            placeholder="New Password"
            solo
            flat
            color="cyan"
            hint="At least 8 characters"
            v-model.trim="formData.password"
            @input="validatePassword($event)"
            :rules="[
              validateRules.required(formData.password, 'New Password'),
              validateRules.minLength(formData.password, 'New Password', 8),
              validateRules.maxLength(formData.password, 'New Password', 16),
            ]"
          ></v-text-field>
        </v-col>
        <v-col md="6" class="py-2" offset-md="3">
          <v-text-field
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            v-on:click:append="show3 = !show3"
            dense
            @keydown.space="(event) => event.preventDefault()"
            filled
            placeholder="Confirm Password"
            solo
            flat
            color="cyan"
            hint="At least 8 characters"
            v-model.trim="formData.password_confirmation"
            :rules="[
              validateRules.required(
                formData.password_confirmation,
                'Confirm Password'
              ),
              validateRules.confirmPassword(
                formData.password_confirmation,
                'Confirm Password',
                formData.password
              ),
              validateRules.minLength(
                formData.password_confirmation,
                'Confirm Password',
                8
              ),
              validateRules.maxLength(
                formData.password_confirmation,
                'Confirm Password',
                16
              ),
            ]"
          ></v-text-field>
        </v-col>
        <v-col class="text-right" md="6" offset-md="3" v-if="!hideInternalBtn">
          <v-btn
            depressed
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            v-on:click="onSubmit"
            class="mx-2 custom-grey-border custom-bold-button text-white"
            color="cyan"
          >
            Update
          </v-btn>
        </v-col>
        <v-col md="6" class="py-2" offset-md="3">
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_min }"
          >
            <i
              class="frmIcon fas"
              :class="has_min ? 'fa-check' : 'fa-times'"
            ></i>
            The password must be at least 8 character
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_uppercase }"
          >
            <i
              class="frmIcon fas"
              :class="has_uppercase ? 'fa-check' : 'fa-times'"
            ></i>
            Has a capital letter
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_lowercase }"
          >
            <i
              class="frmIcon fas"
              :class="has_lowercase ? 'fa-check' : 'fa-times'"
            ></i>
            Has a lowercase letter
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_number }"
          >
            <i
              class="frmIcon fas"
              :class="has_number ? 'fa-check' : 'fa-times'"
            ></i>
            Has a number
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_special }"
          >
            <i
              class="frmIcon fas"
              :class="has_special ? 'fa-check' : 'fa-times'"
            ></i>
            Has a special character
          </p>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "password-update",
  mixins: [ValidationMixin],
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      formValid: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      disabledData: true,
      has_min: false,
      formLoading: false,
      formData: {
        customer: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  props: {
    hideInternalBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSubmit() {
      const _this = this;
      if (!_this.$refs.passwordForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$emit("loading", true);
      _this.customer = _this.$route.params.id;
      let url = "customer/" + _this.customer + "/password";
      _this.$store
        .dispatch(PATCH, {
          url,
          data: _this.formData,
        })
        .then(() => {
          _this.formValid = true;
          _this.formData = {
            password: null,
            password_confirmation: null,
          };
          _this.$emit("password:updated");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
          _this.$emit("loading", false);
        });
    },
    validatePassword(e) {
      // let result = true;
      let inputVal =
        (this.formData.password ? String(this.formData.password) : "") +
        String(e ? e.key : "");
      let a = /[0-9]+/;
      let b = /[a-z]/;
      let c = /[A-Z]/;
      let d = /[!@#$%^&*)(+=._-]/;
      this.has_number = a.test(inputVal);
      this.has_lowercase = b.test(inputVal);
      this.has_uppercase = c.test(inputVal);
      if (e && e == e.toUpperCase()) {
        this.has_lowercase = false;
      }
      this.has_special = d.test(inputVal);
      if (this.formData.password && this.formData.password.length > 7) {
        this.has_min = true;
      } else {
        this.has_min = false;
      }

      if (
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_special &&
        this.has_min &&
        this.has_max
      ) {
        console.log("result");
        this.formValid = true;
        /*  this.disabledData = false; */
      } else {
        /*  this.disabledData = true; */
        this.formValid = false;
      }
    },
    // manageLimit(e) {
    //   // this.disabledDate();
    //   if (this.formData.password && this.formData.password.length > 15) {
    //     e.preventDefault();
    //   }
    // },
    // manageLimitCon(e) {
    //   if (this.formData.password && this.formData.password.length > 15) {
    //     e.preventDefault();
    //   }
    // },
    mounted() {
      const _this = this;
      _this.formData.customer = this.$route.params.id;
    },
    created() {
      const _this = this;
      _this.customer = _this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.frmValidation {
  font-size: 13px;
  margin-bottom: 6px !important;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}

.howToBuild {
  text-align: center;
  color: purple;
}
.howToBuild a {
  color: grey;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}
</style>
